import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from "./guardingTheMotherland.module.scss";

import gneiss from '../assets/image/gneiss.jpg';
import limestone from '../assets/image/limestone.jpg';
import cambrian_clay from '../assets/image/cambrian_clay.jpg';
import chalk_marl_rock from '../assets/image/chalk_marl_rock.jpg';

const GuardingTheMotherland = () => {
  const { pathPart } = useParams();
  const [imageName, setImageName] = useState(null);
  const [pageName, setPageName] = useState(null);

  useEffect(() => {
    let name = gneiss;
    let pageName = 'Горно-Химический Комбинат';

    switch (pathPart) {
      case 'gneiss':
        name = gneiss;
        pageName = 'Горно-Химический Комбинат';
        break;
      case 'limestone':
        name = limestone;
        pageName = 'Московский Метрополитен';
        break;
      case 'cambrian_clay':
        name = cambrian_clay;
        pageName = 'Санкт-Петербургский Метрополитен';
        break;
      case 'chalk_marl_rock':
        name = chalk_marl_rock;
        pageName = 'Тонель под Ла-Маншем';
        break;
      default:
        name = gneiss;
        pageName = 'Горно-Химический Комбинат';
        break;
    }

    setImageName(name);
    setPageName(pageName);
  }, [pathPart]);

  return (
    <>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <main className={styles.main}>
        <div className={styles.imgContainer}>
          {imageName && (
            <img src={imageName} alt="Изображение" />
          )}
        </div>
      </main>
    </>
  );
};

export default GuardingTheMotherland;